import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import SystemRoutes from './views/Navigation/SystemRoutes';
import { observer } from 'mobx-react';
import authStore from './stores/authStore';
import { useEffect } from 'react';
import appConfigStore from './stores/appConfigStore';
import AdminDashboardView from './views/Admin/AdminDashboard';
import GuestRoutes from './views/Navigation/GuestRoutes';

const MyApp = observer(() => {
  if (authStore.isGuest) {
    return <GuestRoutes />;
  }

  return <AppManageStore />;
});

export default MyApp;

const AppManageStore = observer(() => {
  useEffect(() => {
    appConfigStore.getConfig();
  }, []);

  if (appConfigStore.isLoading) {
    return <div />;
  }

  if (appConfigStore.isSuperAdmin) {
    return <AdminDashboardView />;
  }

  const router = createBrowserRouter(SystemRoutes);

  return <RouterProvider router={router} />;
});
