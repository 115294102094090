import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// import LandingView from '../Landing/LandingView';
import AuthView from '../Auth/AuthView';
import RegisterView from '../Register/RegisterView';

export default function GuestRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<AuthView />} />
        <Route path='register/:id' element={<RegisterView />} />
        <Route path='*' element={<Navigate to={'/'} />} />
      </Routes>
    </BrowserRouter>
  );
}
