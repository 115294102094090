import { PencilSquare, ThreeDots, Trash2 } from 'react-bootstrap-icons';
import styles from './styles/Dropdown.module.scss';
import { Dropdown, MenuProps, Modal } from 'antd';
import { useLang } from '../utils/Lang';

interface Action {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  disabled?: boolean;
}

interface Props {
  actions?: Action[];
  onDelete?: () => void;
  onEdit?: () => void;
}

export default function DropdownActions({ actions, onDelete, onEdit }: Props) {
  const lang = useLang();

  const items: MenuProps['items'] = [];

  const onRemove = () => {
    Modal.confirm({
      title: lang.areYouSure,
      okText: lang.delete,
      cancelText: lang.cancel,
      onOk: onDelete,
    });
  };

  if (onEdit) {
    items.push({
      key: 'edit',
      onClick: onEdit,
      icon: <PencilSquare style={{ fontSize: 18, color: 'var(--primary)' }} />,
      label: lang.edit,
    });
  }

  if (actions) {
    actions.forEach((item) => {
      if (item.disabled) {
        console.log('Disabled action', item.label);
      } else {
        items.push({
          key: item.label,
          onClick: item.onClick,
          icon: (
            <div
              style={{
                fontSize: 18,
                color: 'var(--primary)',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {item.icon}
            </div>
          ),
          label: item.label,
        });
      }
    });
  }

  if (onDelete) {
    items.push({
      key: 'delete',
      onClick: onRemove,
      icon: <Trash2 style={{ fontSize: 18, color: 'var(--primary)' }} />,
      label: lang.delete,
    });
  }

  return (
    <Dropdown menu={{ items }}>
      <div className={styles.action}>
        <ThreeDots />
      </div>
    </Dropdown>
  );
}
