import axios from 'axios';
import { makeAutoObservable } from 'mobx';
import AppConsts from '../libs/AppConsts';
import http from '../services/http';
import { IResultDto } from '../models/ResultDto';
import { message } from 'antd';

type Entry = 'Password' | 'Forgot' | 'Register';

class AuthStore {
  isLoading = false;
  entry: Entry = 'Password';
  config?: ILoginDto;
  lookups?: ILookups;
  isMenuExpanded = true;

  get isGuest() {
    return !localStorage.getItem('token');
  }

  get isRegister() {
    return this.entry === 'Register';
  }

  get isLogin() {
    return this.entry === 'Password';
  }

  async login(dto: ILoginDto) {
    this.isLoading = true;
    try {
      const result = await http.post('/auth/login', dto);
      const token = result.data as ILoginDto;
      localStorage.setItem('token', token.authorisation.token);
      window.location.reload();
      window.location.href = '/home';
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  async onVerify(dto: any) {
    this.isLoading = true;
    try {
      await http.post('/auth/register-employee', dto);
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  }

  async logout() {
    this.isLoading = true;
    try {
      // await http.post('/auth/logout');
      localStorage.removeItem('token');
      window.location.reload();
    } finally {
      this.isLoading = false;
    }
  }

  async refreshAccessToken() {
    try {
      const refreshToken = localStorage.getItem('refreshToken');
      const response = await axios.post(`${AppConsts.host}/auth/refresh`, {
        refreshToken,
      });
      const { access_token, refresh_token } = response.data;
      localStorage.setItem('token', access_token);
      localStorage.setItem('refreshToken', refresh_token);
      return access_token as string;
    } catch (error) {
      localStorage.removeItem('token');
      window.location.reload();
      throw error;
    }
  }

  async register(dto: IRegisterDto) {
    this.isLoading = true;
    try {
      const result = await http.post('/auth/register', dto);
      this.entry = 'Password';
      const res = result.data as IResultDto<any>;
      message.success({ content: res.message });
    } finally {
      this.isLoading = false;
    }
  }

  async getCurrentProfile() {
    this.isLoading = true;
    try {
      const result = await http.get('/auth/user');
      this.config = result.data;
    } finally {
      this.isLoading = false;
    }
  }

  async onFetchLookups() {
    this.isLoading = true;
    try {
      const result = await http.get(`/auth/register`);
      this.lookups = result.data as ILookups;
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

const authStore = new AuthStore();
export default authStore;

interface ILoginDto {
  status: string;
  authorisation: {
    token: string;
    type: string;
  };
  email: string;
  password: string;
  profile: {
    id: string;
    firstName: string;
    lastName: string;
    avatar: string;
  };
}

interface IRegisterDto {
  email: string;
  password: string;
  passwordConfirmation: string;
  firstName: string;
  lastName: string;
}

interface ILookups {
  domains: [
    {
      id: string;
      value: string;
      title: string;
    }
  ];
}
