import { makeAutoObservable } from 'mobx';
import { message } from 'antd';
import { State } from '../../enum/state';
import http from '../../services/http';
import { IResultDto } from '../../models/ResultDto';

export default class AdminMenuItemsStore {
  data?: IMenuItem[];

  item?: IMenuItem;

  lookups?: ILookups;

  isLoading = false;

  state: State = 'None';

  note: 'None' | 'New' = 'None';

  async findAll() {
    this.isLoading = true;
    try {
      const result = await http.get('/menu-items');

      const value = result.data as IResultDto<IMenuItem[]>;
      this.data = value.data;
    } finally {
      this.isLoading = false;
    }
  }

  async findLookups() {
    this.isLoading = true;
    try {
      const result = await http.get('/menu-items/create');
      this.lookups = result.data;
    } finally {
      this.isLoading = false;
    }
  }

  async onCreate(values: ICreateUpdateDto) {
    this.isLoading = true;
    try {
      const result = await http.post('/menu-items', values);
      const value = result.data as IResultDto<IMenuItem>;
      this.data?.push(value.data);
      message.success({ content: value.message });
      this.state = 'None';
    } finally {
      this.isLoading = false;
    }
  }

  async onRead(id?: string) {
    if (!id) return;
    this.isLoading = true;
    try {
      const result = await http.get(`/menu-items/${id}`);
      const value = result.data as IResultDto<IMenuItem>;
      this.item = value.data;
      this.state = 'Edit';
    } finally {
      this.isLoading = false;
    }
  }

  async onUpdate(values: ICreateUpdateDto) {
    this.isLoading = true;
    try {
      const result = await http.put(`/menu-items/${this.item?.id}`, values);
      const value = result.data as IResultDto<IMenuItem>;
      if (this.data) {
        this.data = this.data.map((x) => {
          if (x.id === value.data.id) x = value.data;
          return x;
        });
      }
      this.item = value.data;
      message.success({ content: value.message });
      this.state = 'None';
    } finally {
      this.isLoading = false;
    }
  }

  async onDelete(id: string) {
    this.isLoading = true;
    try {
      const result = await http.delete(`/menu-items/${id}`);
      const value = result.data as IResultDto<any>;
      if (this.data) {
        this.data = this.data.filter((e) => e.id !== id);
      }
      message.success({ content: value.message });
      this.state = 'None';
    } finally {
      this.isLoading = false;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

interface ILookups {
  routes: [
    {
      id: string;
      name: string;
      url: string;
    }
  ];
}

interface IMenuItem {
  id: string;
  name: string;
  icon: string;
  order: number;
  parent_id: string;
  children: IMenuItem[];
  route: {
    id: string;
    name: string;
    url: string;
  };
}

interface ICreateUpdateDto {
  parentId: string;
  name: string;
  icon: string;
  order: number;
  routeId: string;
}
