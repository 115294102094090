import { useTranslation } from 'react-i18next';

export const useLang = () => {
  const { t } = useTranslation();

  return {
    today: t('Today_UI'),
    back: t('Back_UI'),
    next: t('Next_UI'),
    week: t('Week_UI'),
    day: t('Day_UI'),
    month: t('Month_UI'),
    timeStart: t('TimeStart_UI'),
    more: t('More_UI'),
    premises: t('Premises_UI'),
    clientsSources: t('ClientsSources_UI'),
    paymentMethods: t('PaymentMethods_UI'),
    areYouSure: t('AreYouSure_UI'),
    add: t('Add_UI'),
    edit: t('Edit_UI'),
    title: t('Title_UI'),
    enterTitle: t('EnterTitle_UI'),
    confirm: t('Confirm_UI'),
    cancel: t('Cancel_UI'),
    addCategory: t('AddCategory_UI'),
    addGood: t('AddGood_UI'),
    type: t('Type_UI'),
    selectType: t('SelectType_UI'),
    measureType: t('MeasureType_UI'),
    selectMeasureType: t('SelectMeasureType_UI'),
    basic: t('Basic_UI'),
    directories: t('Directories_UI'),
    goods: t('Goods_UI'),
    license: t('License_UI'),
    users: t('Users_UI'),
    selectCategory: t('SelectCategory_UI'),
    addComponent: t('AddComponent_UI'),
    editComponent: t('EditComponent_UI'),
    description: t('Description_UI'),
    enterDescription: t('EnterDescription_UI'),
    import: t('Import_UI'),
    enterPrice: t('EnterPrice_UI'),
    price: t('Price_UI'),
    unit: t('Unit_UI'),
    selectUnit: t('SelectUnit_UI'),
    save: t('Save_UI'),
    notProvided: t('NotProvided_UI'),
    delete: t('Delete_UI'),
    productInformation: t('ProductInformation_UI'),
    components: t('Components_UI'),
    addProducts: t('AddProducts_UI'),
    addProduct: t('AddProduct_UI'),
    profile: t('Profile_UI'),
    owner: t('Owner_UI'),
    firstName: t('FirstName_UI'),
    lastName: t('LastName_UI'),
    active: t('Active_UI'),
    inactive: t('Inactive_UI'),
    exit: t('Exit_UI'),
    client: t('Client_UI'),
    source: t('Source_UI'),
    dealsCount: t('DealsCount_UI'),
    addClient: t('AddClient_UI'),
    middleName: t('MiddleName_UI'),
    clientSource: t('ClientSource_UI'),
    search: t('Search_UI'),
    enterFirstName: t('EnterFirstName_UI'),
    enterLastName: t('EnterLastName_UI'),
    enterMiddleName: t('EnterMiddleName_UI'),
    selectClientSource: t('SelectClientSource_UI'),
    enterPhoneNumber: t('EnterPhoneNumber_UI'),
    addPhoneNumber: t('AddPhoneNumber_UI'),
    selectSocialNetwork: t('SelectSocialNetwork_UI'),
    enterAccountUrl: t('EnterAccountUrl_UI'),
    addSocialNetwork: t('AddSocialNetwork_UI'),
    editClient: t('EditClient_UI'),
    fio: t('Fio_UI'),
    yes: t('Yes_UI'),
    no: t('No_UI'),
    canvas: t('Canvas_UI'),
    colors: t('Colors_UI'),
    width: t('Width_UI'),
    details: t('Details_UI'),
    canvasName: t('CanvasName_UI'),
    objects: t('Objects_UI'),
    addNewDeal: t('AddNewDeal_UI'),
    selectClient: t('SelectClient_UI'),
    dealType: t('DealType_UI'),
    selectDealType: t('SelectDealType_UI'),
    dealSource: t('DealSource_UI'),
    selectDealSource: t('SelectDealSource_UI'),
    shortAddress: t('ShortAddress_UI'),
    pleaseEnterShortAddress: t('PleaseEnterShortAddress_UI'),
    editNote: t('EditNote_UI'),
    addNote: t('AddNote_UI'),
    enterText: t('EnterText_UI'),
    table: t('Table_UI'),
    canban: t('Canban_UI'),
    list: t('List_UI'),
    addObject: t('AddObject_UI'),
    addMeasure: t('AddMeasure_UI'),
    date: t('Date_UI'),
    addDrawing: t('AddDrawing_UI'),
    drawing: t('Drawing_UI'),
    objectPlan: t('ObjectPlan_UI'),
    drawings: t('Drawings_UI'),
    notes: t('Notes_UI'),
    products: t('Products_UI'),
    notify: t('Notify_UI'),
    responsible: t('Responsible_UI'),
    addTask: t('AddTask_UI'),
    dateStart: t('DateStart_UI'),
    dateEnd: t('DateEnd_UI'),
    author: t('Author_UI'),
    measures: t('Measures_UI'),
    tasks: t('Tasks_UI'),
    montages: t('Montages_UI'),
    agreement: t('Agreement_UI'),
    leadStatus: t('LeadStatus_UI'),
    leadType: t('LeadType_UI'),
    address: t('Address_UI'),
    creationDate: t('CreationDate_UI'),
    finalPrice: t('FinalPrice_UI'),
    discount: t('Discount_UI'),
    object: t('Object_UI'),
    dateStartMontage: t('DateStartMontage_UI'),
    addMontage: t('AddMontage_UI'),
    dateMontage: t('DateMontage_UI'),
    complete: t('Complete_UI'),
    quantity: t('Quantity_UI'),
    goodsAndServices: t('GoodsAndServices_UI'),
    services: t('Services_UI'),
    addService: t('AddService_UI')
  };
};
