import { makeAutoObservable } from 'mobx';
import AdminRolesStore from './adminRolesStore';
import AdminRoutesStore from './adminRoutesStore';
import AdminLicensesStore from './adminLicensesStore';
import AdminMenuItemsStore from './adminMenuItemsStore';
import AdminPoliciesStore from './adminPoliciesStore';

class AdminStore {
  roles = new AdminRolesStore();
  routes = new AdminRoutesStore();
  licenses = new AdminLicensesStore();
  menu = new AdminMenuItemsStore();
  policy = new AdminPoliciesStore();

  async findLicenses() {
    await this.licenses.findAll();
  }

  async findSettings() {
    await this.routes.findAll();
    await this.menu.findAll();
    await this.roles.findAll();
    await this.policy.findAll();
  }

  constructor() {
    makeAutoObservable(this);
  }
}

const adminStore = new AdminStore();

export default adminStore;
