/* eslint-disable import/no-anonymous-default-export */
import dayjs from 'dayjs';
import i18n from './Locale/i18n';

type DateFormat = 'DD MMM. YYYY' | 'DD MMMM YYYY' | 'DD.MM.YYYY' | 'HH:mm' | 'DD.MM.YYYY - HH:mm' | 'DD.MM' | 'dd';

class Utils {
  format(e?: Date, format?: DateFormat) {
    if (!e) {
      return ' - ';
    }
    return dayjs(e).locale(i18n.language).format(format ? format : 'DD MMM. YYYY');
  }

  getEnumLabel(data: any[], value?: number): string {
    try {
      return data.find((e) => e.value === value)?.title;
    } catch (e) {
      return '';
    }
  }

  array(count: number) {
    return Array.from(Array(count).keys());
  }

  generateRandomHexColors(n: number): string[] {
    function getRandomHexColor(): string {
      return '#' + Math.floor(Math.random() * 16777215).toString(16);
    }

    const colors: string[] = [];
    for (let i = 0; i < n; i++) {
      colors.push(getRandomHexColor());
    }
    return colors;
  }

  generateRandomColor() {
    return '#' + (((1 << 24) * Math.random()) | 0).toString(16);
  }

  getDaysSinceDate(selectedDate?: Date) {
    if (!selectedDate) {
      return 0;
    }
    let diff = dayjs().diff(dayjs(selectedDate), 'day');
    if (diff === 0) {
      diff = 1;
    }
    return diff;
  }

  delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  compressImage = (
    file: File,
    maxWidth: number,
    maxHeight: number,
    qualityStep: number = 0.1
  ): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        let canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject(new Error('Failed to get canvas context'));
          return;
        }

        ctx.drawImage(img, 0, 0, width, height);

        const compress = (quality: number) => {
          canvas.toBlob(
            (blob) => {
              if (!blob) {
                reject(new Error('Failed to compress image'));
                return;
              }

              if (blob.size > 100 * 1024 && quality > 0.1) {
                compress(quality - qualityStep);
              } else {
                resolve(blob);
              }
            },
            'image/jpeg',
            quality
          );
        };

        compress(0.9); // Start compression with high quality
      };
      img.onerror = (error) => {
        reject(error);
      };
    });
  };
}

export default new Utils();
