// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Scaffold_header__aPeON {
  padding: 30px 24px 0px 24px;
  display: flex;
  align-items: center;
  column-gap: 18px;
}
.Scaffold_header__aPeON h1 {
  font-size: 32px;
  color: var(--night900);
  padding: 0px;
  margin: 0px;
}
.Scaffold_header__aPeON .Scaffold_back__4jIbF {
  background-color: var(--night100);
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.Scaffold_header__aPeON .Scaffold_back__4jIbF:hover {
  cursor: pointer;
  background-color: var(--night200);
}

.Scaffold_area__dG47u {
  background-color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Scaffold.module.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,eAAA;EACA,sBAAA;EACA,YAAA;EACA,WAAA;AACJ;AAEE;EACE,iCAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;AAEI;EACE,eAAA;EACA,iCAAA;AAAN;;AAKA;EACE,kCAAA;AAFF","sourcesContent":[".header {\n  padding: 30px 24px 0px 24px;\n  display: flex;\n  align-items: center;\n  column-gap: 18px;\n\n  h1 {\n    font-size: 32px;\n    color: var(--night900);\n    padding: 0px;\n    margin: 0px;\n  }\n\n  .back {\n    background-color: var(--night100);\n    width: 28px;\n    height: 28px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 4px;\n\n    &:hover {\n      cursor: pointer;\n      background-color: var(--night200);\n    }\n  }\n}\n\n.area {\n  background-color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Scaffold_header__aPeON`,
	"back": `Scaffold_back__4jIbF`,
	"area": `Scaffold_area__dG47u`
};
export default ___CSS_LOADER_EXPORT___;
