// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/Logo-06.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Admin_header__0VzqP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 12px;
}
.Admin_header__0VzqP button {
  width: 120px;
}

.Admin_logo__2CH3j {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 50px;
  width: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 5px 8px;
}

.Admin_admin__F92NV {
  font-size: 18px;
  font-weight: 700;
  color: var(--night900);
  padding: 14px 16px;
}`, "",{"version":3,"sources":["webpack://./src/views/Admin/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;AACF;AACE;EACE,YAAA;AACJ;;AAEA;EACE,yDAAA;EACA,YAAA;EACA,YAAA;EACA,wBAAA;EACA,4BAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,kBAAA;AACF","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0px 12px;\n\n  button {\n    width: 120px;\n  }\n}\n.logo {\n  background-image: url(../../assets/Logo-06.svg);\n  height: 50px;\n  width: 120px;\n  background-size: contain;\n  background-repeat: no-repeat;\n  margin: 5px 8px;\n}\n\n.admin {\n  font-size: 18px;\n  font-weight: 700;\n  color: var(--night900);\n  padding: 14px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Admin_header__0VzqP`,
	"logo": `Admin_logo__2CH3j`,
	"admin": `Admin_admin__F92NV`
};
export default ___CSS_LOADER_EXPORT___;
