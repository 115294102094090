import styles from './index.module.scss';
import Logo from './assets/logo.webp';
import CeilingImg from './assets/ceiling 1.webp';

export default function LandingView() {
  const headerPoints = [
    {
      img: CeilingImg,
      title: 'Монтаж',
      subtitle: 'Потолоков',
    },
    {
      img: CeilingImg,
      title: 'Монтаж',
      subtitle: 'Окон',
    },
    {
      img: CeilingImg,
      title: 'Монтаж',
      subtitle: 'Плитки',
    },
    {
      img: CeilingImg,
      title: 'Монтаж',
      subtitle: 'Ворот',
    },
    {
      img: CeilingImg,
      title: 'Монтаж',
      subtitle: 'Дверей',
    },
    {
      img: CeilingImg,
      title: 'Монтаж',
      subtitle: 'Жалюзей',
    },
    {
      img: CeilingImg,
      title: 'Монтаж',
      subtitle: 'Полов',
    },
    {
      img: CeilingImg,
      title: 'Монтаж',
      subtitle: 'Кондиционеров',
    },
  ];

  const benefitPoints = [
    {
      img: CeilingImg,
      title: 'Всё необходимое для бизнеса в одной программе',
    },
    {
      img: CeilingImg,
      title: 'Простой и понятный интерфейс ',
    },
    {
      img: CeilingImg,
      title: 'Удобный построитель чертежей',
    },
    {
      img: CeilingImg,
      title: 'Бесплатные обновления',
    },
    {
      img: CeilingImg,
      title: 'Вся история взаимодействия с клиентами: звонки, заказы',
    },
    {
      img: CeilingImg,
      title: 'Выгрузка данных в Excel',
    },
    {
      img: CeilingImg,
      title: 'Техническая поддержка',
    },
    {
      img: CeilingImg,
      title: 'Отчет по продажам и монтажам',
    },
    {
      img: CeilingImg,
      title: 'Финансовый отчет по движению денег',
    },
    {
      img: CeilingImg,
      title: 'Хранение файлов в облаке',
    },
  ];
  return (
    <div className={styles.area}>
      <nav>
        <div className={styles.content}>
          <img src={Logo} alt='flitecrm.com' />
          <div className={styles.menu}>
            <a href=''>Для кого</a>
            <a href=''>Возможности</a>
            <a href=''>Стоимость</a>
            <a href=''>Работать в Flite CRM</a>
            <a href='' className={styles.contact}>Получить консультацию</a>
          </div>
        </div>
      </nav>
      <div className={styles.header}>
        <div className={styles.content}>
          <h1>
            FLITE – программа по <br /> управлению монтажным <br /> бизнесом
          </h1>
          <div className={styles.points}>
            {headerPoints.map((e) => (
              <div className={styles.point}>
                <img src={e.img} alt={e.subtitle} />
                <div>
                  <p>{e.title}</p>
                  <h6>{e.subtitle}</h6>
                </div>
              </div>
            ))}
          </div>
          <button>Попробовать бесплатно</button>
          <div className={styles.person} />
        </div>
      </div>
      <div className={styles.earn}>
        <h2>Зарабатывай больше и освободи время с Flite! </h2>
        <div className={styles.content}>
          <div className={styles.devices} />
          <div>
            <ul>
              <li>Удобный календарь замеров, монтажей, задач.</li>
              <li>Построение плана помещения и других чертежей.</li>
              <li>Составление сметы для клиента прямо в программе.</li>
              <li>Интеграции с телефониями, мессенджерами и сайтами.</li>
              <li>Работа с клиентской базой.</li>
              <li>Рассчет маржи каждой сделки.</li>
              <li>Контроль движения денег: предоплата, рассрочка, остаток.</li>
              <li>Дашборды.</li>
            </ul>
            <button>Попробовать бесплатно</button>
          </div>
        </div>
      </div>
      <div className={styles.benefits}>
        <h2>Преимущества Flite</h2>
        <div className={styles.benefitsWrap}>
          <div className={styles.points}>
            {benefitPoints.map((e) => (
              <div>
                <img src={e.img} alt={e.title} />
                <p>{e.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.mobile}>
        <h1>
          Мобильное веб-приложение для <br /> тех,кто работает на себя
        </h1>
        <div className={styles.content}>
          <ul>
            <li>До 5 пользователей</li>
            <li>Дисковое пространство: 10 гб</li>
            <li>Отчет по продажам и монтажам</li>
            <li>Календарь замеров и монтажей</li>
            <li>Выгрузка данных в Excel</li>
            <li>Финансовый отчет (доход/расход/прибыль)</li>
          </ul>
          <ul>
            <li>Мобильный построитель чертежей</li>
            <li>Формирование смет и документов</li>
            <li>Техническая поддержка</li>
            <li>Бесплатные обновления</li>
            <li>Интеграции: с телефониями, сайтами, мессенджерами</li>
          </ul>
          <div className={styles.laptops} />
        </div>
      </div>
    </div>
  );
}
