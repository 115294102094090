import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import LandingView from '../Landing/LandingView';
import AuthView from '../Auth/AuthView';

export default function GuestRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LandingView />} />
        <Route path='auth' element={<AuthView />} />
        <Route path='*' element={<Navigate to={'/'} />} />
      </Routes>
    </BrowserRouter>
  );
}
