// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Settings_area__wt82V {
  padding: 12px;
}

.Settings_column__YnKdm {
  border: 1px solid var(--night200);
  min-height: 88vh;
  overflow-y: auto;
}
.Settings_column__YnKdm .Settings_add__a\\+hkd {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--night200);
  border-radius: 0px;
  position: sticky;
  top: 0px;
}

.Settings_policy__91AAQ {
  padding: 12px 16px;
  border-bottom: 1px solid var(--night200);
}
.Settings_policy__91AAQ .Settings_content__KvOC5 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--night200);
  margin-bottom: 12px;
  padding-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/views/Admin/views/Settings/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,iCAAA;EACA,gBAAA;EACA,gBAAA;AACF;AACE;EACE,WAAA;EACA,aAAA;EACA,2BAAA;EACA,wCAAA;EACA,kBAAA;EACA,gBAAA;EACA,QAAA;AACJ;;AAGA;EACE,kBAAA;EACA,wCAAA;AAAF;AAEE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,wCAAA;EACA,mBAAA;EACA,oBAAA;AAAJ","sourcesContent":[".area {\n  padding: 12px;\n}\n\n.column {\n  border: 1px solid var(--night200);\n  min-height: 88vh;\n  overflow-y: auto;\n\n  .add {\n    width: 100%;\n    display: flex;\n    justify-content: flex-start;\n    border-bottom: 1px solid var(--night200);\n    border-radius: 0px;\n    position: sticky;\n    top: 0px;\n  }\n}\n\n.policy {\n  padding: 12px 16px;\n  border-bottom: 1px solid var(--night200);\n\n  .content {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 1px solid var(--night200);\n    margin-bottom: 12px;\n    padding-bottom: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"area": `Settings_area__wt82V`,
	"column": `Settings_column__YnKdm`,
	"add": `Settings_add__a+hkd`,
	"policy": `Settings_policy__91AAQ`,
	"content": `Settings_content__KvOC5`
};
export default ___CSS_LOADER_EXPORT___;
