import { observer } from 'mobx-react';
import { useEffect } from 'react';
import adminStore from '../../../../stores/admin/adminStore';
import { Button, Col, Flex, Form, Input, InputNumber, Modal, Row, Select, Space, Tag } from 'antd';
import styles from './index.module.scss';
import DropdownActions from '../../../../components/DropdownActions';
import { PencilSquare, Trash2 } from 'react-bootstrap-icons';
import { useIcons } from '../../../../hooks/UseIcons';

const AdminSettingsView = observer(() => {
  const [form] = Form.useForm();

  const { allIcons, getIcon } = useIcons();

  useEffect(() => {
    adminStore.findSettings();
  }, []);

  const onCancel = () => {
    form.resetFields();
    adminStore.policy.state = 'None';
    adminStore.routes.state = 'None';
    adminStore.menu.state = 'None';
    adminStore.roles.state = 'None';
  };

  const onOpenCreatePolicy = () => {
    form.resetFields();
    adminStore.policy.state = 'Create';
  };

  const onConfirmPolicy = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (adminStore.policy.state === 'Create') {
        await adminStore.policy.onCreate(values);
      }
      if (adminStore.policy.state === 'Edit') {
        await adminStore.policy.onUpdate(values);
      }
    } catch (error) {
      console.error(error);
    } finally {
      adminStore.findSettings();
    }
  };

  const onEditPolicy = async (id: string) => {
    await adminStore.policy.onRead(id);
    adminStore.policy.state = 'Edit';
    form.setFieldsValue(adminStore.policy.item);
    const routes = adminStore.policy.item?.routes.map((e) => e.id);
    form.setFieldValue('routeIds', routes);
  };

  const onOpenRoute = () => {
    form.resetFields();
    adminStore.routes.state = 'Create';
  };

  const onConfirmRoute = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (adminStore.routes.state === 'Create') {
        await adminStore.routes.onCreate(values);
      }
      if (adminStore.routes.state === 'Edit') {
        await adminStore.routes.onUpdate(values);
      }
    } catch (error) {
      console.error(error);
    } finally {
      adminStore.findSettings();
    }
  };

  const onConfirmRole = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      if (adminStore.roles.state === 'Edit') {
        await adminStore.roles.onAssign(values);
      }
    } catch (error) {
      console.error(error);
    } finally {
      adminStore.findSettings();
    }
  };

  const onEditRoute = async (id: string) => {
    await adminStore.routes.onRead(id);
    form.setFieldsValue(adminStore.routes.item);
  };

  const onOpenMenu = async () => {
    form.resetFields();
    await adminStore.menu.findLookups();
    adminStore.menu.state = 'Create';
  };

  const onConfirmMenu = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (adminStore.menu.state === 'Create') {
        await adminStore.menu.onCreate(values);
      }
      if (adminStore.menu.state === 'Edit') {
        await adminStore.menu.onUpdate(values);
      }
    } catch (error) {
      console.error(error);
    } finally {
      adminStore.findSettings();
    }
  };

  const onEditMenu = async (id: string) => {
    await adminStore.menu.findLookups();
    await adminStore.menu.onRead(id);
    form.setFieldsValue(adminStore.menu.item);
    form.setFieldValue('routeId', adminStore.menu.item?.route.id);
  };

  return (
    <Row gutter={12} className={styles.area}>
      <Col span={6}>
        <div className={styles.column}>
          <Button
            onClick={onOpenCreatePolicy}
            className={styles.add}
            disabled={adminStore.policy.isLoading}
          >
            Добавить метрику
          </Button>
          {adminStore.policy.data?.map((policy) => (
            <div key={policy.id} className={styles.policy}>
              <div className={styles.content}>
                {policy.name}
                <DropdownActions
                  actions={[
                    {
                      icon: <PencilSquare />,
                      label: 'Редактировать',
                      onClick: () => onEditPolicy(policy.id),
                    },
                    {
                      icon: <Trash2 />,
                      label: 'Удалить',
                      onClick: () => adminStore.policy.onDelete(policy.id),
                    },
                  ]}
                />
              </div>
              <Space wrap>
                {policy.routes.map((route) => (
                  <Tag key={route.id} color='blue'>
                    {route.name}
                  </Tag>
                ))}
              </Space>
            </div>
          ))}
        </div>
      </Col>
      <Col span={6}>
        <div className={styles.column}>
          <Button className={styles.add} disabled={adminStore.routes.isLoading}>
            Роли
          </Button>
          {adminStore.roles.roles?.map((role) => (
            <div key={role.id} className={styles.policy}>
              <div className={styles.content}>
                {role.label}
                <DropdownActions
                  actions={[
                    {
                      icon: <PencilSquare />,
                      label: 'Редактировать',
                      onClick: async () => {
                        adminStore.roles.roleId = role.id;
                        await adminStore.roles.findLookups();
                        const routes = role.routes.map((e) => e.id);
                        form.setFieldValue('routeIds', routes);
                      },
                    },
                  ]}
                />
              </div>
              <Space wrap>
                {role.routes.map((route) => (
                  <Tag key={route.id} color='blue'>
                    {route.name}
                  </Tag>
                ))}
              </Space>
            </div>
          ))}
        </div>
      </Col>
      <Col span={6}>
        <div className={styles.column}>
          <Button
            className={styles.add}
            onClick={onOpenRoute}
            disabled={adminStore.routes.isLoading}
          >
            Добавить роут
          </Button>
          {adminStore.routes.data?.map((route) => (
            <div key={route.id} className={styles.policy}>
              <div
                className={styles.content}
                style={{ borderBottom: 'none', padding: 0, margin: 0 }}
              >
                {route.name}
                <DropdownActions
                  actions={[
                    {
                      icon: <PencilSquare />,
                      label: 'Редактировать',
                      onClick: () => onEditRoute(route.id),
                    },
                    {
                      icon: <Trash2 />,
                      label: 'Удалить',
                      onClick: () => adminStore.routes.onDelete(route.id),
                    },
                  ]}
                />
              </div>
            </div>
          ))}
        </div>
      </Col>
      <Col span={6}>
        <div className={styles.column}>
          <Button className={styles.add} onClick={onOpenMenu} disabled={adminStore.menu.isLoading}>
            Добавить меню
          </Button>
          {adminStore.menu.data?.map((menu) => {
            const Icon = getIcon(menu.icon);

            return (
              <div key={menu.id} className={styles.policy}>
                <div className={styles.content}>
                  <Flex align='center' gap={8}>
                    {Icon}
                    {menu.name}
                  </Flex>

                  <DropdownActions
                    actions={[
                      {
                        icon: <PencilSquare />,
                        label: 'Редактировать',
                        onClick: () => onEditMenu(menu.id),
                      },
                      {
                        icon: <Trash2 />,
                        label: 'Удалить',
                        onClick: () => adminStore.menu.onDelete(menu.id),
                      },
                    ]}
                  />
                </div>
                <div style={{ fontSize: 12 }}>Роут: {`/ ${menu.route.url}`}</div>
                <div style={{ fontSize: 12 }}>Порядок: {`${menu.order}`}</div>
              </div>
            );
          })}
        </div>
      </Col>
      <Modal
        open={adminStore.policy.state !== 'None'}
        title='Метрика'
        onOk={onConfirmPolicy}
        onCancel={onCancel}
        okButtonProps={{ disabled: adminStore.policy.isLoading }}
      >
        <Form form={form} layout='vertical'>
          <Form.Item name='name' label='Название' rules={[{ required: true, message: '' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='value' label='Значение' initialValue={0}>
            <InputNumber />
          </Form.Item>
          <Form.Item name='description' label='Описание' rules={[{ required: true, message: '' }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name='routeIds' label='Роуты'>
            <Select
              mode='multiple'
              options={adminStore.routes.data?.map((e) => ({ value: e.id, label: e.name }))}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={adminStore.routes.state !== 'None'}
        title='Роут'
        onOk={onConfirmRoute}
        onCancel={onCancel}
        okButtonProps={{ disabled: adminStore.routes.isLoading }}
      >
        <Form form={form} layout='vertical'>
          <Form.Item name='name' label='Название' rules={[{ required: true, message: '' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='url' label='Путь' rules={[{ required: true, message: '' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={adminStore.roles.state !== 'None'}
        title='Роуты доступные для роля'
        onOk={onConfirmRole}
        onCancel={onCancel}
        okButtonProps={{ disabled: adminStore.roles.isLoading }}
      >
        <Form form={form} layout='vertical'>
          <Form.Item name='routeIds' label='Роуты' rules={[{ required: true, message: '' }]}>
            <Select
              mode='multiple'
              options={adminStore.roles.lookups?.routes.map((e) => ({
                value: e.id,
                label: e.name,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={adminStore.menu.state !== 'None'}
        title='Меню'
        onOk={onConfirmMenu}
        onCancel={onCancel}
        okButtonProps={{ disabled: adminStore.menu.isLoading }}
      >
        <Form form={form} layout='vertical'>
          <Form.Item name='name' label='Название' rules={[{ required: true, message: '' }]}>
            <Input />
          </Form.Item>
          <Form.Item name='icon' label='Иконка' rules={[{ required: true, message: '' }]}>
            <Select>
              {allIcons.map((e) => (
                <Select.Option value={e.key}>
                  <div style={{ display: 'flex', alignItems: 'center', columnGap: 12 }}>
                    {e.value}
                    <div>{e.key}</div>
                  </div>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name='order' label='Порядок' rules={[{ required: true, message: '' }]}>
            <InputNumber />
          </Form.Item>
          <Form.Item name='routeId' label='Роут' rules={[{ required: true, message: '' }]}>
            <Select
              options={adminStore.menu.lookups?.routes.map((e) => ({ value: e.id, label: e.name }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Row>
  );
});

export default AdminSettingsView;
