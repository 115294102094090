import React from 'react';
import styles from './styles/Scaffold.module.scss';
import { Spin } from 'antd';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router';

interface Props {
  title: string;
  children: React.ReactNode;
  isLoading?: boolean;
  isBack?: boolean;
  trailing?: React.ReactNode;
}

export default function Scaffold({ title, children, isLoading, isBack, trailing }: Props) {
  const nav = useNavigate();

  const goBack = () => {
    nav(-1);
  };
  return (
    <div className={styles.area}>
      <div className={styles.header}>
        {isBack && (
          <div>
            <div onClick={goBack} className={styles.back}>
              <ArrowLeft />
            </div>
          </div>
        )}
        <h1>{title}</h1>
        {trailing && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {trailing}
          </div>
        )}
      </div>
      <div>{isLoading ? <Loading /> : children}</div>
    </div>
  );
}

function Loading() {
  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}
    >
      <Spin />
    </div>
  );
}
