import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import i18n from '../utils/Locale/i18n';
import { Modal } from 'antd';
import authStore from '../stores/authStore';
import AppConsts from '../libs/AppConsts';
import getTimezone from '../utils/getTimezone';

interface QueueItem {
  resolve: (token: string) => void;
  reject: (reason: any) => void;
}

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean; // Optional property to indicate retry status
}

const http = axios.create({
  baseURL: AppConsts.host,
  headers: { 'Content-Type': 'application/json' },
});

let isRefreshing = false;
let failedQueue: QueueItem[] = [];

const processQueue = (error: any, token: string | null = null): void => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else if (token) {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

http.defaults.headers['accept-language'] = i18n.language;

// Request interceptor to add the token to every request
http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const selectedLanguage = i18n.language;
    const timezone = getTimezone();

    config.headers['accept-language'] = selectedLanguage;
    config.headers['x-timezone'] = timezone;
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle 401 Unauthorized globally
http.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (!error.config) {
      return;
    }
    const originalRequest = error.config as CustomAxiosRequestConfig;

    if (error.response?.status === 400) {
      const data = error.response.data as any;

      // Check if 'messages' exist in the response, containing nested errors
      let text = data.message;
      if (data.messages) {
        text = Object.keys(data.messages)
          .map((field) => {
            // Join messages for each field into a single string
            return `${field}: ${data.messages[field].join(', ')}`;
          })
          .join('\n');
      }

      // Display error with parsed messages in a modal
      Modal.error({
        title: 'Внимание',
        content: text,
        okText: 'Понятно',
        onOk: () => {
          Modal.destroyAll();
        },
      });
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (!originalRequest.headers) {
        originalRequest.headers = {};
      }

      if (isRefreshing) {
        return new Promise<string>((resolve, reject) => {
          failedQueue.push({
            resolve: (token: string) => resolve(token),
            reject: (err: any) => reject(err),
          });
        })
          .then((token) => {
            originalRequest.headers!['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        authStore
          .refreshAccessToken()
          .then((newToken: string) => {
            http.defaults.headers.common['Authorization'] = 'Bearer ' + newToken;
            originalRequest.headers!['Authorization'] = 'Bearer ' + newToken;
            processQueue(null, newToken);
            resolve(axios(originalRequest));
          })
          .catch((refreshError) => {
            processQueue(refreshError, null);
            reject(refreshError);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default http;
