import { makeAutoObservable } from 'mobx';

import { message } from 'antd';
import { State } from '../../enum/state';
import http from '../../services/http';
import { IResultDto } from '../../models/ResultDto';

export default class AdminPoliciesStore {
  data?: IPolicy[];

  item?: IPolicy;

  isLoading = false;

  state: State = 'None';

  async findAll() {
    this.isLoading = true;
    try {
      const result = await http.get('/policies');

      const value = result.data as IResultDto<IPolicy[]>;
      this.data = value.data;
    } finally {
      this.isLoading = false;
    }
  }

  async onCreate(values: ICreatePolicy) {
    this.isLoading = true;
    try {
      const result = await http.post('/policies', values);
      const value = result.data as IResultDto<IPolicy>;
      this.data?.push(value.data);
      message.success({ content: value.message });
    } finally {
      this.isLoading = false;
      this.state = 'None';
    }
  }

  async onRead(id?: string) {
    if (!id) return;
    this.isLoading = true;
    try {
      const result = await http.get(`/policies/${id}`);
      const value = result.data as IResultDto<IPolicy>;
      this.item = value.data;
    } finally {
      this.isLoading = false;
      this.state = 'None';
    }
  }

  async onUpdate(values: IUpdatePolicy) {
    this.isLoading = true;
    try {
      const result = await http.put(`/policies/${this.item?.id}`, values);
      const value = result.data as IResultDto<IPolicy>;
      if (this.data) {
        this.data = this.data.map((x) => {
          if (x.id === value.data.id) x = value.data;
          return x;
        });
      }
      this.item = value.data;
      message.success({ content: value.message });
    } finally {
      this.isLoading = false;
      this.state = 'None';
    }
  }

  async onDelete(id: string) {
    this.isLoading = true;
    try {
      const result = await http.delete(`/policies/${id}`);
      const value = result.data as IResultDto<any>;
      if (this.data) {
        this.data = this.data.filter((e) => e.id !== id);
      }
      message.success({ content: value.message });
      this.state = 'None';
    } finally {
      this.isLoading = false;
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

interface IPolicy {
  id: string;
  name: string;
  value: number;
  description: string;
  routes: [
    {
      id: string;
      name: string;
      url: string;
    }
  ];
}

interface ICreatePolicy {
  name: string;
  value: number;
  description: string;
}

interface IUpdatePolicy {
  name: string;
  value: number;
  description: string;
  routeIds: string[];
}
