import { makeAutoObservable } from 'mobx';
import { message } from 'antd';
import { State } from '../../enum/state';
import http from '../../services/http';
import { IResultDto } from '../../models/ResultDto';

export default class AdminLicensesStore {
  data?: IOwner[];

  item?: ILicense;

  lookups?: ILookups;

  isLoading = false;

  state: State = 'None';

  note: 'None' | 'New' = 'None';

  async findAll() {
    this.isLoading = true;
    try {
      const result = await http.get('/licenses/owners');

      const value = result.data as IResultDto<IOwner[]>;
      this.data = value.data;
    } finally {
      this.isLoading = false;
    }
  }

  async onDelete(id: string) {
    this.isLoading = true;
    try {
      await http.delete(`/licenses/${id}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findLookups() {
    this.isLoading = true;
    try {
      const result = await http.get('/licenses/create');
      this.lookups = result.data;
    } finally {
      this.isLoading = false;
    }
  }

  async onCreate(values: ICreateUpdateDto) {
    this.isLoading = true;
    try {
      const result = await http.post('/licenses', values);
      const value = result.data as IResultDto<ILicense>;
      message.success({ content: value.message });
    } finally {
      this.isLoading = false;
      this.state = 'None';
      this.findAll();
    }
  }

  async onRead(id?: string) {
    if (!id) return;
    this.isLoading = true;
    try {
      const result = await http.get(`/licenses/${id}`);
      const value = result.data as IResultDto<ILicense>;
      this.item = value.data;
      this.state = 'Edit';
    } finally {
      this.isLoading = false;
    }
  }

  // async onUpdate(values: ICreateUpdateDto) {
  //   this.isLoading = true;
  //   try {
  //     const result = await http.put(`/licenses/${this.item?.id}`, values);
  //     const value = result.data as IResultDto<ILicense>;
  //     if (this.data) {
  //       this.data = this.data.map((x) => {
  //         if (x.id === value.data.id) x = value.data;
  //         return x;
  //       });
  //     }
  //     this.item = value.data;
  //     message.success({ content: value.message });
  //     this.state = 'None';
  //   } finally {
  //     this.isLoading = false;
  //   }
  // }

  // async onDelete(id: string) {
  //   this.isLoading = true;
  //   try {
  //     const result = await http.delete(`/licenses/${id}`);
  //     const value = result.data as IResultDto<any>;
  //     if (this.data) {
  //       this.data = this.data.filter((e) => e.id !== id);
  //     }
  //     message.success({ content: value.message });
  //     this.state = 'None';
  //   } finally {
  //     this.isLoading = false;
  //   }
  // }

  constructor() {
    makeAutoObservable(this);
  }
}

interface ILicense {
  id: string;
  name: string;
  user: {};
  expirationDate: Date;
  createdAt: Date;
}

interface ICreateUpdateDto {
  name: string;
  userId: string;
  expirationDate: Date;
  policyIds: string[];
}

interface IOwner {
  id: string;
  email: string;
  profile: {
    id: string;
    firstName: string;
    lastName: string;
    avatar: string;
  };
  license: {
    id: string;
    name: String;
    policies: [
      {
        id: string;
        name: string;
      }
    ];
    expirationDate: Date;
    createdAt: Date;
  };
  role: {
    name: string;
    label: string;
  };
}

interface ILookups {
  owners: [
    {
      id: string;
      email: string;
      profile: {
        id: string;
        firstName: string;
        lastName: string;
        avatar: string;
        createdAt: Date;
        updatedAt: Date;
      };
    }
  ];
  policices: [
    {
      id: string;
      name: string;
      value: number;
      description: string;
      routes: [
        {
          id: string;
          name: string;
          url: string;
        }
      ];
    }
  ];
}
