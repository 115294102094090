import { makeAutoObservable } from 'mobx';
import { State } from '../../enum/state';
import http from '../../services/http';
import { IResultDto } from '../../models/ResultDto';
import { message } from 'antd';

export default class AdminRolesStore {
  roles?: IRole[];

  lookups?: ILookups;

  isLoading = false;

  state: State = 'None';

  roleId?: string;

  async findAll() {
    this.isLoading = true;
    try {
      const result = await http.get('/roles');

      const value = result.data as IResultDto<IRole[]>;
      this.roles = value.data;
    } finally {
      this.isLoading = false;
    }
  }

  async findLookups() {
    this.isLoading = true;
    try {
      const result = await http.get('/roles/create');
      this.lookups = result.data;
      this.state = 'Edit';
    } finally {
      this.isLoading = false;
    }
  }

  async onAssign(dto: IAssignDto) {
    this.isLoading = true;
    try {
      const result = await http.post(`/roles/${this.roleId}/routes`, dto);
      const value = result.data as IResultDto<IRole>;
      message.success({ content: value.message });
    } finally {
      this.isLoading = false;
      this.state = 'None';
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

interface IAssignDto {
  routeIds: string[];
}

interface IRole {
  id: string;
  name: string;
  label: number;
  guardName: string;
  routes: IRoute[];
}

interface IRoute {
  id: string;
  name: string;
  url: string;
}

interface ILookups {
  routes: IRoute[];
}
