import { observer } from 'mobx-react';
import { useEffect } from 'react';
import Scaffold from '../../../../components/Scaffold';
import adminStore from '../../../../stores/admin/adminStore';
import { DatePicker, Form, Input, Modal, Select, Table } from 'antd';
import DropdownActions from '../../../../components/DropdownActions';
import { PlusCircle } from 'react-bootstrap-icons';
import dayjs from 'dayjs';
import Utils from '../../../../utils/Utils';

const AdminClientsView = observer(() => {
  const [form] = Form.useForm();

  useEffect(() => {
    adminStore.findLicenses();
    return () => {
      adminStore.licenses.data = undefined;
    };
  }, []);

  const onCreate = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      await adminStore.licenses.onCreate(values);
    } catch (error) {
      console.error(error);
    }
  };

  const onCancel = () => {
    adminStore.licenses.state = 'None';
  };

  return (
    <Scaffold title='Клиенты'>
      <Table
        dataSource={adminStore.licenses.data}
        pagination={false}
        columns={[
          {
            title: 'Клиент',
            width: 200,
            render: (_, i) => [i.profile?.lastName, i.profile?.firstName].join(' '),
          },
          {
            title: 'Email',
            dataIndex: 'email',
          },
          {
            title: 'Лицензия',
            render: (_, i) => (i.license ? i.license.name : '-'),
          },
          {
            title: 'Срок лицензии',
            dataIndex: '',
            render: (_, i) =>
              i.license ? `${Utils.format(i.license.expirationDate, 'DD.MM.YYYY')}` : '-',
          },
          {
            title: ' ',
            width: 70,
            render: (_, i) => (
              <DropdownActions
                actions={[
                  {
                    icon: <PlusCircle />,
                    label: 'Добавить лицензию',
                    onClick: async () => {
                      await adminStore.policy.findAll();
                      form.setFieldValue('userId', i.id);
                      adminStore.licenses.state = 'Create';
                    },
                  },
                ]}
              />
            ),
          },
        ]}
      />
      <Modal
        title='Назначить лицензия'
        open={adminStore.licenses.state === 'Create'}
        onOk={onCreate}
        onCancel={onCancel}
        okButtonProps={{ disabled: adminStore.licenses.isLoading }}
        okText='Добавить'
      >
        <Form form={form} layout='vertical'>
          <Form.Item name={'userId'} hidden />
          <Form.Item name={'name'} label='Название лицензии'>
            <Input />
          </Form.Item>
          <Form.Item name={'expirationDate'} label='Укажите срок'>
            <DatePicker minDate={dayjs()}  format={'DD-MM-YYYY'} />
          </Form.Item>
          <Form.Item name={'policyIds'} label='Метрики'>
            <Select
              mode='multiple'
              options={adminStore.policy.data?.map((e) => ({ value: e.id, label: e.name }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Scaffold>
  );
});

export default AdminClientsView;
