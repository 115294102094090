import { Button, ConfigProvider, Layout, Menu } from 'antd';
import { observer } from 'mobx-react';
import styles from './index.module.scss';
import { Bag, Gear } from 'react-bootstrap-icons';
import authStore from '../../stores/authStore';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import AdminClientsView from './views/Clients/AdminClientsView';
import AdminSettingsView from './views/Settings/AdminSettingsView';

const { Content, Sider, Header } = Layout;

const AdminDashboardView = observer(() => {
  const onLogout = () => {
    authStore.logout();
  };
  return (
    <BrowserRouter>
      <Layout>
        <Sider>
          <div className={styles.logo} />
          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  colorBgContainer: 'transparent',
                  itemSelectedBg: 'white',
                },
              },
            }}
          >
            <SideMenu />
          </ConfigProvider>
        </Sider>
        <Layout>
          <Header className={styles.header}>
            <div className={styles.admin}>Управление</div>

            <Button onClick={onLogout} type='primary'>
              Выйти
            </Button>
          </Header>
          <Content>
            <Routes>
              <Route path='clients' element={<AdminClientsView />} />
              <Route path='settings' element={<AdminSettingsView />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
});

export default AdminDashboardView;

function SideMenu() {
  const nav = useNavigate();

  return (
    <Menu
      onClick={(e) => nav(`/${e.key}`)}
      items={[
        { icon: <Bag />, key: 'clients', label: 'Клиенты' },
        { icon: <Gear />, key: 'settings', label: 'Настройки' },
      ]}
    />
  );
}
