// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dropdown_action__ujOmj {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--night200);
  border-radius: 4px;
  transition: all 0.2s;
  background-color: white;
}
.Dropdown_action__ujOmj:hover {
  cursor: pointer;
  border-color: var(--night500);
  color: var(--primary);
}`, "",{"version":3,"sources":["webpack://./src/components/styles/Dropdown.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iCAAA;EACA,kBAAA;EACA,oBAAA;EACA,uBAAA;AACF;AACE;EACE,eAAA;EACA,6BAAA;EACA,qBAAA;AACJ","sourcesContent":[".action {\n  width: 28px;\n  height: 28px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  border: 1px solid var(--night200);\n  border-radius: 4px;\n  transition: all 0.2s;\n  background-color: white;\n\n  &:hover {\n    cursor: pointer;\n    border-color: var(--night500);\n    color: var(--primary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action": `Dropdown_action__ujOmj`
};
export default ___CSS_LOADER_EXPORT___;
